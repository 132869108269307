import { ThunkDispatch } from "@reduxjs/toolkit";
import { Button, Card, Col, Form, Image, Row } from "react-bootstrap";
import { GoArrowLeft } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ForgotBanner from "../../assets/images/forgot-password-banner.jpg";
import Logo from "../../assets/images/logo-white.svg";
import CommonFormikComponent from '../../components/common/CommonFormikComponent';
import ThemeInputText from "../../components/common/InputText";
import Loader from "../../components/common/Loader";
import { baseRoutes } from "../../routes/base-routes";
import { setupAccountAction } from "../../store/reducer/auth";
import { ForgotPasswordSchema } from "../../validations/auth-schema/forgot-password";
import { useRef } from "react";

export const SetAccountPassword = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const isLoading = useSelector((state: any) => state.auth.loading);
  const formRef:any = useRef();

  // Initial Values
  const initialValues: Record<string, any> = {
    user_email: ''
  };

  const handleSubmit = (values: Record<string, any>, actions: any) => {
    actions.setSubmitting(false);
    dispatch(setupAccountAction(values.user_email)).then(()=>{
      formRef.current.setFieldValue("user_email", "");
      formRef.current.setTouched({
          user_email: false
      })
    })
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Card className="border-0 overflow-auto min-vh-100 w-100 text-center z-1 py-4 px-3 p-md-5 rounded-0">
        <div className="theme-auth-header pt-2 pb-4 w-75 mx-auto">
          <Link to="/" className="d-inline-block">
            <Image src={Logo} alt="Logo" width={330} height={60} fluid />
          </Link>
        </div>
        <Row className="justify-content-center g-0 mt-md-3">
          <Col xs={12}>
            <Card className="px-4 pb-4 px-md-5 pb-md-5 text-start rounded-3 border-0 mx-auto max-w-450">
              <h1 className="fs-4 fw-semibold my-2 mt-md-4 pt-3 pt-md-4 text-center text-primary">Account Setup</h1>
              <p className="text-center mb-4">Please enter your email address to set <br className="d-sm-block d-none" /> your&nbsp;account.</p>
              <CommonFormikComponent
                validationSchema={ForgotPasswordSchema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                innerRef={formRef}
              >
                {(formikProps) => (
                  <>
                    <Form.Group className="mb-4 pb-1">
                      <ThemeInputText
                        inputClassName="theme-inputtext-lg"
                        controlId="user_email"
                        label='Email*'
                        handleBlur={formikProps.handleBlur}
                        handleChange={formikProps.handleChange}
                        value={formikProps.values.user_email}
                        touched={formikProps.touched.user_email}
                        errorsField={formikProps.errors.user_email}
                      />
                    </Form.Group>
                    <Button className="w-100 btn-lg mt-2 fw-normal" type="submit">
                      Submit
                    </Button>
                  </>
                )}
              </CommonFormikComponent>
            </Card>
            <div className="my-4 py-1">
              <Link to={baseRoutes.login.path} className="d-inline-flex align-items-center gap-2 text-decoration-underline small text-white">
                <GoArrowLeft size={20} />
                <span>{baseRoutes.login.pathName}</span>
              </Link>
            </div>
          </Col>
        </Row>
        <Card.ImgOverlay className="p-0 pe-none user-select-none z-n1 rounded-0">
          <Card.Img
            src={ForgotBanner}
            alt="Set Account Banner"
            className="w-100 h-100 object-fit-cover rounded-0"
          />
        </Card.ImgOverlay>
      </Card>
    </>
  );
};
