import { NavLink } from "react-router-dom";
import { Button, Nav, Navbar } from "react-bootstrap";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { NavItems } from "./navItems";
import "./sidebar.scss";

const Sidebar = ({ isActiveSidebar, toggleSidebarButton }: any) => {

  return (
    <div className={`sidebarMenu ${isActiveSidebar ? "sidebarAction" : ""}`}>
      <Button
        variant="link"
        onClick={toggleSidebarButton}
        className="align-items-center bg-white border border-2 btn d-xl-flex h-20 justify-content-center mt-2 p-0 position-absolute rounded-circle start-100 top-0 translate-middle-x w-20 z-2 toogle-button d-none"
        aria-label="Toggle Sidebar Menu"
      >
        {isActiveSidebar ? (
          <IoIosArrowForward color="#ABABAB" size={12} />
        ) : (
          <IoIosArrowBack color="#ABABAB" size={12} />
        )}
      </Button>
      <Navbar
        bg="primary"
        variant="dark"
        expand="xxl"
        className="w-100 h-100 p-0"
      >
        <div className="d-flex flex-column w-100 h-100">
          <div className="overflow-x-hidden overflow-y-scroll sidebarList h-100">
            <Nav
              defaultActiveKey="/app/dashboard"
              as="ul"
              className="flex-column p-2"
            >
              {NavItems.map((elem) => {
                const { id, menuName, menuIcon, path, disabled, hideInMobile } = elem;
                return (
                  <Nav.Item as="li" key={id} className={hideInMobile? 'd-md-block d-none': '' }>
                    <Nav.Link
                      as={NavLink}
                      to={path}
                      className={`align-items-center d-flex px-0 sidebarLink text-nowrap rounded ${disabled? 'disabled': '' }`}
                    >
                      <span className="py-1 text-center min-w-44 sidebarIcon">
                        {menuIcon}
                      </span>
                      <span className="hideInSmallSidebar">{menuName}</span>
                    </Nav.Link>                    
                  </Nav.Item>
                );
              })}
            </Nav>
          </div>
        </div>
      </Navbar>
    </div>
  );
};

export default Sidebar;
