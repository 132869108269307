// config.ts

interface AppConfig {
  basePath: any;
  reduxSecretKey: any;
  apiEndPoint:any;
  passwordEncryptionKey:any;
}
const appConfig: AppConfig = {
  basePath: process.env.REACT_APP_BASE_PATH,
  reduxSecretKey: process.env.REACT_APP_REDUX_KEY,
  apiEndPoint: process.env.REACT_APP_API_END_POINT,
  passwordEncryptionKey: process.env.REACT_APP_PASSWORD_ENCRYPT
};
  
  export default appConfig;